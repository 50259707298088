import React from "react"
import { graphql } from "gatsby"
import { Box, Heading } from "@chakra-ui/react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const TestPage = ({ data }) => {
console.log(data.wpProject.hollandProjects)

const img1 = getImage(data.wpProject.hollandProjects.image1.localFile)
console.log(img1)
const prj = data.wpProject.hollandProjects;




    return (
        <Box mt="20vh">
        <Heading>{prj.projecttitle} </Heading>
        <Box w="100vw" h="50vw">
        <GatsbyImage image={img1} alt={data.wpProject.hollandProjects.projecttitle}  />
        </Box>
        </Box>
    )
}

export default TestPage

export const query = graphql`
  {
    wpProject(title: {eq: "Le Lapin"}) {
      title
      hollandProjects {
        projecttitle
        projectdescription
        projectdate
        projectcategories
        image1 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image2 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image3 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image4 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image5 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image6 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image7 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image8 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image9 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image10 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image11 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image12 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image13 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image14 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image15 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image16 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image17 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image18 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image19 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image20 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image21 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image22 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image23 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image24 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image25 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image26 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image27 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image28 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image29 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        image30 {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`
